import { ReactNode } from "react";
import { FluidObject } from "gatsby-image";

export interface LocationSchema {
  name?: string;
  address?: {
    country?: string;
    locality?: string;
    region?: string;
  };
}

export interface PerformanceSchema {
  as?: string;
  date?: string;
  endTime?: string;
  hasRecordings?: boolean;
  location?: LocationSchema;
  setlist?: string[];
  setlistItems?: SetlistItemSchema[];
  slug?: string;
  sources?: string[];
  startTime?: string;
  type?: string;
}

export interface SongSchema {
  authors?: string[];
  filename?: string;
  html?: string;
  publish_date?: string;
  recordings?: RecordingSchema[];
  source?: string;
  performance?: PerformanceSchema;
  slug?: string;
  title?: string;
}

export interface PostSchema {
  date?: string;
  excerpt?: string;
  html?: string;
  image?: FluidObject;
  imageFit: "cover" | "contain";
  slug?: string;
  tags?: string[];
  title?: string;
}

interface DiscographySchema {
  title?: string;
  artworkFluid?: FluidObject;
  date?: string;
  slug?: string;
  songs?: SongSchema[];
}

export interface RecordingSchema {
  performanceKey?: string;
  source?: string;
  id?: string;
  performance?: PerformanceSchema;
  song?: SongSchema;
}

interface SetlistItemSchema {
  trackNumber?: string;
  song?: SongSchema;
  recordings?: RecordingSchema[];
}

export interface AllPostsSchema {
  allPosts: {
    nodes: PostSchema[];
  };
}
export interface AllDiscographySchema {
  allDiscography: {
    nodes: DiscographySchema[];
  };

  defaultImage?: {
    FluidObject;
  };
}

export interface AllPerformanceSchema {
  allPerformances: {
    nodes: PerformanceSchema[];
  };
}

export interface SongProps
  extends Omit<Omit<SongSchema, "recordings">, "authors"> {
  key?: string;
  song?: string;
  authors?: string;
  times_played?: number;
  first_performed?: string;
  last_performed?: string;
  recordings_count?: number;
  recordings?: {
    dateStr?: string;
    locationStr?: string;
    venueStr?: string;
    isPlaying?: string;
    performanceSlug?: string;
    source?: string;
    id?: string;
    performance: PerformanceSchema;
  }[];
}

interface SongFilter {
  selectedFilter: SongIndexFiltersEnum;
  setSelectedFilter: (v: SongIndexFiltersEnum) => any;
}

export interface FilteredSongSchema extends SongFilter {
  songs: SongSchema[];
}

export interface PerformanceProps {
  as?: string;
  date?: string;
  hasRecordings?: boolean;
  hasSetlist?: boolean;
  startTime?: string;
  endTime?: string;
  key?: string;
  setlistItems?: SetlistItemSchema[];
  setlistLength?: number;
  slug?: string;
  type?: string;
  venueAddress?: string;
  venueName?: string;
  sources?: string[];
}

export interface TrackProps {
  key: string;
  recordingID: string | null;
  title: string;
  trackNumber: string;
  setlistItem: SetlistItemSchema;
  hasRecording: boolean;
  songSlug: string;
  isPlaying: boolean;
  onPlayClick: () => void;
}

export interface DiscographyProps {
  title?: string;
  artwork?: ReactNode;
  date?: string;
  slug?: string;
  songs?: {
    slug?: string;
    title?: string;
    date?: string;
  }[];
}

export interface NowPlayingProps {
  onPlay: () => void;
  onPause: () => void;
  onPrev: () => void;
  onNext: () => void;
  isPlaying: boolean;
  currentRecording: RecordingSchema;
}

export interface SongIndexPageProps extends SongFilter {
  location: Location;
  songs: SongProps[];
  selectedSortKey: string;
  selectedSortDirection: SortDirections;
  setSelectedSortDirection: (v: SortDirections) => any;
  setSelectedSortKey: (v: string) => any;
}

export interface SongShowPageProps extends SongProps {
  onSongPlay: (id: string) => any;
  location: Location;
}
export interface PerformanceShowPageProps extends PerformanceProps {
  selectedSource: string;
  onSourceChange: (e) => void;
  location: Location;
  tracks: TrackProps[];
}

export interface PerformanceIndexPageProps {
  upcoming: PerformanceProps[];
  past: PerformanceProps[];
  location: Location;
}

export interface PostShowPost extends Omit<PostSchema, "image"> {
  image?: ReactNode;
}

export interface PostShowPageProps extends PostShowPost {
  location: Location;
}

export interface DiscographyIndexPageProps {
  location: Location;
  discography: DiscographyProps[];
}

export interface DiscographyShowPageProps extends DiscographyProps {
  location: Location;
}

export interface PostIndexPost
  extends Omit<Omit<PostSchema, "image">, "imageFit"> {
  image?: ReactNode;
}

export interface PostIndexPageProps {
  totalPosts: number;
  location: Location;
  posts: PostIndexPost[];
  allTags: string[];
  tagSizes: {
    [key: number]: number;
  };
  selectedTags: string[];
  selectTag: (t: string) => void;
  deselectTag: (t: string) => void;
  setSelectedTags: any;
}

export interface IndexPageProps {
  location: Location;
}

export const SONG_INDEX_COLUMNS = [
  { key: "song", title: "Song" },
  { key: "authors", title: "Authors" },
  { key: "publish_date", title: "Publish Date" },
  { key: "recordings_count", title: "# Recordings" },
  { key: "times_played", title: "Times Played" },
  { key: "first_performed", title: "First Performed" },
  { key: "last_performed", title: "Last Performed" },
];

export enum SortDirections {
  Asc = "ASC",
  Desc = "DESC",
}

export enum SongIndexFiltersEnum {
  All = "All",
  Originals = "Originals",
  Covers = "Covers",
}

export const SONG_INDEX_FILTERS = {
  ALL: "All",
  ORIGINALS: "Originals",
  COVERS: "Covers",
};

export const PERFORMANCE_INDEX_COLUMNS = [
  {
    title: "Date",
    key: "date",
  },
  {
    title: "Venue",
    key: "venueName",
  },
  {
    title: "Performance",
    key: "type",
  },
  {
    title: "Performer",
    key: "as",
  },
  {
    title: "Recordings",
    key: "hasRecordings",
  },
  {
    title: "Setlist",
    key: "setlistLength",
  },
  {
    title: "Location",
    key: "locationStr",
  },
];
