import React from "react";
import {
  SongIndexPageProps,
  SONG_INDEX_COLUMNS,
  SongIndexFiltersEnum,
  SortDirections,
} from "../lib";
import { navigate } from "gatsby";
import PageLayout from "./PageLayout";

export default ({
  songs,
  selectedFilter,
  setSelectedFilter,
  selectedSortKey,
  selectedSortDirection,
  setSelectedSortDirection,
  setSelectedSortKey,
}: SongIndexPageProps) => {
  return (
    <PageLayout>
      <article>
        <h3>Filter</h3>
        {Object.keys(SongIndexFiltersEnum).map(
          (filterKey: SongIndexFiltersEnum) => {
            const filter = SongIndexFiltersEnum[filterKey];
            return (
              <label key={filterKey}>
                <input
                  onChange={() => setSelectedFilter(filter)}
                  type="radio"
                  value={filter}
                  checked={filter === selectedFilter}
                />{" "}
                {filterKey}
              </label>
            );
          }
        )}
        <h3>Sort</h3>
        <select
          value={selectedSortKey}
          onChange={(e) => setSelectedSortKey(e.target.value)}
        >
          {SONG_INDEX_COLUMNS.map(({ key, title }) => (
            <option value={key} key={key}>
              {title}
            </option>
          ))}
        </select>
        <select
          value={selectedSortDirection}
          onChange={(e) =>
            setSelectedSortDirection(e.target.value as SortDirections)
          }
        >
          {Object.keys(SortDirections).map((k) => (
            <option key={SortDirections[k]} value={SortDirections[k]}>
              {SortDirections[k]}
            </option>
          ))}
        </select>
        <hr />
        <table>
          <thead>
            <tr>
              {SONG_INDEX_COLUMNS.map(({ key, title }) => (
                <th key={key} style={{ textDecoration: "underline" }}>
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {songs.map((song) => (
              <tr
                style={{ textDecoration: "underline", color: "blue" }}
                onClick={() => navigate(song.slug)}
                key={song.key}
                aria-label="Song"
              >
                {SONG_INDEX_COLUMNS.map(({ key }) => (
                  <td aria-label={`${key}-column`} key={key}>
                    {key === "song" ? (
                      <a href={song.slug}>{song[key]}</a>
                    ) : (
                      song[key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </article>
    </PageLayout>
  );
};
